const ZH_CN = {
    "Hello": "你好",
    "About Us": "关于我们",
    "Vision": "愿景",
    "Mission": "使命",
    "Join Us": "加入我们",
    "Rewards": "奖励",
    "Subscribe": "订阅",
    "Learn More": "探索更多",
    "headerTitle1": "我们致力于打造一个更加开放、透明、公正的数字,",
    "headerTitle2": "创作和短视频平台，共同探索数字创作与短视频的未来",
    "headerTitle3": "我们致力于打造一个更加开放、透明、公正的数字, 创作和短视频平台，共同探索数字创作与短视频的未来",
    "headerSubTitle": "我们通过建立一个去中心化的生态系统来彻底改变短视频行业，并利用区块链技术的力量为数字创作者和观众提供支持。",
    "AboutUsTitle": '凯胜国际是一家致力于推动数字创作和短视频行业发展的公司',
    "AboutUsDesc": "我们通过建立一个去中心化的生态系统来彻底改变短视频行业，并利用区块链技术的力量为数字创作者和观众提供支持。",
    "Our Vision": "我们的愿景",
    "VisionTitle": "打造一个更加开放、透明、公正的数字创作和短视频行业",
    "VisionDesc": "为了实现这个愿景，我们开发了Xcubbe应用程序，这是一个多元化去中心化的应用程序，包括商城、短视频、社交、游戏等多个功能，可以满足用户在不同场景下的需求。",
    "Marketplace": "商城",
    "Short Video": "短视频",
    "Social": "社交",
    "Games": "游戏",
    "Information": "资讯",
    "Our Mission": "我们的使命",
    "MissionTitle": "通过我们去中心化的短视频平台为全球数字创作者和观众赋能",
    "MissionDesc": "我们会将内容标记为 NFT，提供全球曝光。我们将打造视频创作与消费引领的金融新时代，巩固加密社区内的独立性和增长。通过我们强大的实力为每个人创造创新的投资机会与生态系统。",
    "Our Strength": "我们的优势",
    "StrengthTitle": "凯胜国际的巨大优势在于我们的区块链解决方案",
    "StrengthDesc": "这使得我们的平台能够提供安全、透明的体验。再由庞大志趣相投的用户群，组成一个繁荣的社区，帮助用户获得更大的收入潜力。" ,
    "Our Creator": "独特机制",
    "CreatorTitle": "我们拥有独特的股权分配机制",
    "CreatorDesc": "通过Xcubbe的独特机制，奖励社区中最活跃的成员，并实现更公平的财富分配和奖励。",
    "Our Belief": "我们相信",
    "BeliefTitle": "区块链技术的去中心化和透明性，可以为数字创作者和观众提供更好的保护和支持，同时也能够推动整个短视频行业的发展。",
    "BeliefDesc": "让我们一起建立一个更加公正、开放的数字创作和短视频行业吧！",
    "newsletterTitle": "保持关注，获取最新资讯！",
    "NewsletterDesc": "想要了解更多资讯和动态吗？快来加入我们的新闻订阅吧！",
    "Enter Your Email": "输入你的电子邮件",
    "Subscribe Now": "立刻订阅",
    "Join Us and": "加入我们，您可以：",
    "card1Title": "在我们的平台上观看优质短视频内容",
    "card2Title": "与全球的数字创作者互动，分享您的想法和创意",
    "card3Title": "在我们的商城购买您喜欢的商品，支持数字创作者",
    "card4Title": "参与我们的游戏，获得更多奖励"
}
 
 export default ZH_CN;