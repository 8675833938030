import React, { useEffect, useState } from 'react';
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Index(props) {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    // document.documentElement.classList.remove("nav-open");
    // window.scrollTo(0, 0);
    // document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const [imageIndex, setImageIndex] = React.useState(0);
  const [imageIndex2, setImageIndex2] = React.useState(0);

  const settings = {
    dots: false,
    infinite: false,
    lazyLoad: 'ondemand',
    initOnLoad: true,
    nextSlidesToPreload: 1,
    autoplay: false,
    speed: 300,
    slidesToShow: window.innerWidth > 992 ? 4.5 : window.innerWidth > 677 ? 2.5 : 1.3,
    centerMode: false,
    centerPadding: 0,
    beforeChange: (current, next) => { setImageIndex(next) },
  };

  const settings2 = {
    dots: false,
    infinite: true,
    lazyLoad: 'ondemand',
    initOnLoad: true,
    nextSlidesToPreload: 1,
    autoplay: true,
    speed: 300,
    slidesToShow: window.innerWidth > 992 ? 5 : window.innerWidth > 677 ? 3 : 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 0,
    swipe: true,
    beforeChange: (current, next) => { setImageIndex2(next) },
  };

  return (
    <>
      <IndexNavbar />
      <div className='position-relative'>
        <div className="row justify-content-center mx-0">
          <div className="col-12 text-center">
            <div className='row justify-content-center align-items-center headerSection'>
              <div className='col-12 col-md-11 col-lg-10'>
                <div className='mb-4 d-none d-md-block'>
                  <span className="font-CN-Medium headerTitle stickyParticles">{translate(props.lang, "headerTitle1")}</span> <br />
                  <span className="font-CN-Medium headerTitle stickyParticles">{translate(props.lang, "headerTitle2")}</span>
                </div>

                <div className='mb-4 d-block d-md-none'>
                  <span className="font-CN-Medium headerTitle stickyParticles">{translate(props.lang, "headerTitle3")}</span>
                </div>

                <div className='mb-4'>
                  <span className="font-CN-Regular headerSubTitle stickyParticles">{translate(props.lang, "headerSubTitle")}</span>
                </div>

                <div className='mb-5 pt-1'>
                  <div className='d-flex justify-content-center align-items-center'>
                    <div className=''>
                      <img src={require('../assets/img/appleStore.png').default} className="img-fluid downloadAppImg stickyParticles" />
                    </div>
                    <div className='ml-4 ml-md-5'>
                      <img src={require('../assets/img/googlePlay.png').default} className="img-fluid downloadAppImg stickyParticles" />
                    </div>
                  </div>
                </div>

                <div className='mb-5'>
                  <img src={require('../assets/img/headerImg.png').default} className="img-fluid stickyParticles" />
                </div>

                <div className='mb-5'>
                  <div className='d-flex justify-content-center align-items-center'>
                    <div className='stickyParticles'>
                      <FaLinkedinIn fontSize={25} color={'#459DFF'} />
                    </div>
                    <div className='ml-4 stickyParticles'>
                      <FaTelegramPlane fontSize={25} color={'#459DFF'} />
                    </div>
                    <div className='ml-4 stickyParticles'>
                      <FaFacebookF fontSize={25} color={'#459DFF'} />
                    </div>
                    <div className='ml-4 stickyParticles'>
                      <FaInstagram fontSize={25} color={'#459DFF'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <img src={require('../assets/img/particle1.png').default} className="img-fluid particlePos1 floating" />
        <img src={require('../assets/img/particle2.png').default} className="img-fluid particlePos2 floating2" />
        <img src={require('../assets/img/particle3.png').default} className="img-fluid particlePos3 floating2" />
        <img src={require('../assets/img/particle4.png').default} className="img-fluid particlePos4 floating" />
        <img src={require('../assets/img/particle5.png').default} className="img-fluid particlePos5 floating2" />
        <img src={require('../assets/img/particle6.png').default} className="img-fluid particlePos6 floating" />
        <img src={require('../assets/img/particle7.png').default} className="img-fluid particlePos7 floating" />
        <img src={require('../assets/img/particle8.png').default} className="img-fluid particlePos8 floating2" />
        <img src={require('../assets/img/particle9.png').default} className="img-fluid particlePos9 floating2" />
        <img src={require('../assets/img/particle10.png').default} className="img-fluid particlePos10 floating" />

      </div>

      <div className='headerBarSection position-relative pb-5'>
        <div className='bar1' />
        <div className='bar2'>
          <div className='row mx-0 justify-content-center align-items-center h-100'>
            <div className='w-20 text-center'>
              <span className='barText text-white'>
                {translate(props.lang, "USDT")}
              </span>
            </div>

            <div className='w-20 text-center'>
              <span className='barText text-white'>
                {translate(props.lang, "WIN Coin")}
              </span>
            </div>

            <div className='w-20 text-center'>
              <span className='barText text-white'>
                {translate(props.lang, "XC1")}
              </span>
            </div>

            <div className='w-20 text-center'>
              <span className='barText text-white'>
                {translate(props.lang, "XC3")}
              </span>
            </div>

            <div className='w-20 text-center'>
              <span className='barText text-white'>
                {translate(props.lang, "XC4")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center mx-0 py-5">
        <div className="col-12 text-center">
          <div className='aboutUsSection position-relative pb-5' id="aboutUs">
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-md-10 col-lg-10'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-12 col-md-12 col-lg-5'>
                    <div className='text-center text-lg-left'>
                      <span className="font-CN-Regular sectionHeaderText stickyParticles">{translate(props.lang, "About Us")}</span>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionTitleText stickyParticles">{translate(props.lang, "AboutUsTitle")}</span>
                      </div>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionDescText stickyParticles">{translate(props.lang, "AboutUsDesc")}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-12 col-lg-6'>
                    <img src={require('../assets/img/aboutUsImg.png').default} className="img-fluid stickyParticles" />
                  </div>
                </div>
              </div>
            </div>
            <img src={require('../assets/img/particle11.png').default} className="img-fluid particlePos11 floating" />
            <img src={require('../assets/img/particle12.png').default} className="img-fluid particlePos12 floating2" />
            <img src={require('../assets/img/particle13.png').default} className="img-fluid particlePos13 floating" />
            <img src={require('../assets/img/particle14.png').default} className="img-fluid particlePos14 floating2" />
          </div>

          <div className='visionSection position-relative' id="vision">
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-md-12 col-lg-10'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-12 col-md-10 col-lg-8'>
                    <div className='text-center'>
                      <span className="font-CN-Regular sectionHeaderText stickyParticles">{translate(props.lang, "Our Vision")}</span>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionTitleText stickyParticles">{translate(props.lang, "VisionTitle")}</span>
                      </div>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionDescText stickyParticles">{translate(props.lang, "VisionDesc")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={require('../assets/img/particle15.png').default} className="img-fluid particlePos15 floating" />
          </div>
        </div>
      </div>

      <div className='pb-5 d-none d-md-block'>
        <Slider {...settings} arrows={false}>
          <div className={imageIndex === 0 ? "" : ""} />
          <div className={imageIndex === 1 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide1.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon1.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Marketplace")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 2 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide2.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon2.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Short Video")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 3 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide3.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon3.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Social")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 4 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide4.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon4.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Games")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 5 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide5.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon5.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Information")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 6 ? "" : ""} />
        </Slider>
      </div>

      <div className='pb-5 d-block d-md-none'>
        <Slider {...settings} arrows={false}>
          <div className={imageIndex === 0 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide1.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon1.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Marketplace")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 1 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide2.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon2.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Short Video")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 2 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide3.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon3.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Social")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 3 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide4.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon4.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Games")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>

          <div className={imageIndex === 4 ? "inactiveVisionSlide" : "inactiveVisionSlide"} >
            <div className='position-relative'>
              <img src={require('../assets/img/visionSlide5.png').default} className="img-fluid visionSlideBg" />
              <div className='visionSlideContentPos'>
                <img src={require('../assets/img/visionSlideIcon5.png').default} className="img-fluid visionSlideIconSize" />
                <div className='py-3'>
                  <span className='visionSlideText font-CN-Medium'>{translate(props.lang, "Information")}</span>
                </div>
                <hr className='visionSlideLine' />
              </div>
            </div>
          </div>
        </Slider>
      </div>

      <div className="row justify-content-center mx-0">
        <div className="col-12 text-center">
          <div className='missionSection position-relative py-5' id="mission">
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-md-10 col-lg-10'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-12 col-md-12 col-lg-6 mb-5'>
                    <img src={require('../assets/img/missionImg.png').default} className="img-fluid missionImgSize stickyParticles" />
                  </div>

                  <div className='col-12 col-md-10 col-lg-6'>
                    <div className='ext-center text-lg-left'>
                      <span className="font-CN-Regular sectionHeaderText stickyParticles">{translate(props.lang, "Our Mission")}</span>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionTitleText stickyParticles">{translate(props.lang, "MissionTitle")}</span>
                      </div>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionDescText stickyParticles">{translate(props.lang, "MissionDesc")}</span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <img src={require('../assets/img/particle16.png').default} className="img-fluid particlePos16 floating" />
            <img src={require('../assets/img/particle17.png').default} className="img-fluid particlePos17 floating2" />
            <img src={require('../assets/img/particle18.png').default} className="img-fluid particlePos18 floating" />
            <img src={require('../assets/img/particle19.png').default} className="img-fluid particlePos19 floating2" />
            <img src={require('../assets/img/particle20.png').default} className="img-fluid particlePos20 floating" />
          </div>

          <div className='position-relative py-5' id="joinUs">
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-md-12 col-lg-10'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-12 col-md-8'>
                    <div className='text-center'>
                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionTitleText">{translate(props.lang, "Join Us and")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row justify-content-center align-items-center pt-5'>
              <div className='col-12 col-md-11 col-lg-9'>
                <ul id="cards">
                  <li class="card" id="card_1">
                    <div className='position-relative'>
                      <div class="card__content row m-0 justify-content-end">
                        <div className='col-9 p-0'>
                          <video autoPlay={true} playsInline muted loop id="myVideo" style={{ width: "100%", height: '100%' }}>
                            <source src={require('../assets/img/1.mp4').default} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                      <div className='card1Bg' />
                      <div className='card1TextPos w-100 h-100'>
                        <div className='row justify-content-start text-left align-items-center h-100'>
                          <div className='col-8 col-md-6 col-md-5 px-4 px-md-5'>
                            <span className='cardTitle font-CN-Regular'>
                              {translate(props.lang, "card1Title")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="card" id="card_2">
                    <div className='position-relative'>
                      <div class="card__content row m-0 justify-content-end">

                        <div className='col-9 p-0'>
                          <video autoPlay={true} playsInline muted loop id="myVideo" style={{ width: "100%", height: '100%' }}>
                            <source src={require('../assets/img/2.mp4').default} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                      <div className='card2Bg' />
                      <div className='card1TextPos w-100 h-100'>
                        <div className='row justify-content-start text-left align-items-center h-100'>
                          <div className='col-8 col-md-6 col-md-5 px-4 px-md-5'>
                            <span className='cardTitle font-CN-Regular'>
                              {translate(props.lang, "card2Title")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="card" id="card_3">
                    <div className='position-relative'>
                      <div class="card__content row m-0 justify-content-end">

                        <div className='col-9 p-0'>
                          <video autoPlay={true} playsInline muted loop id="myVideo" style={{ width: "100%", height: '100%' }}>
                            <source src={require('../assets/img/3.mp4').default} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                      <div className='card3Bg' />
                      <div className='card1TextPos w-100 h-100'>
                        <div className='row justify-content-start text-left align-items-center h-100'>
                          <div className='col-8 col-md-6 col-md-5 px-4 px-md-5'>
                            <span className='cardTitle font-CN-Regular'>
                              {translate(props.lang, "card3Title")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li class="card" id="card_4">
                    <div className='position-relative'>
                      <div class="card__content row m-0 justify-content-end">

                        <div className='col-9 p-0'>
                          <video autoPlay={true} playsInline muted loop id="myVideo" style={{ width: "100%", height: '100%' }}>
                            <source src={require('../assets/img/4.mp4').default} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                      <div className='card4Bg' />
                      <div className='card1TextPos w-100 h-100'>
                        <div className='row justify-content-start text-left align-items-center h-100'>
                          <div className='col-8 col-md-6 col-md-5 px-4 px-md-5'>
                            <span className='cardTitle font-CN-Regular'>
                              {translate(props.lang, "card4Title")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='strengthSection position-relative py-5'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-md-10 col-lg-10'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-12 col-md-12 col-lg-6 order-2 order-lg-1 mb-5'>
                    <div className='text-center text-lg-left'>
                      <span className="font-CN-Regular sectionHeaderText stickyParticles">{translate(props.lang, "Our Strength")}</span>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionTitleText stickyParticles">{translate(props.lang, "StrengthTitle")}</span>
                      </div>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionDescText stickyParticles">{translate(props.lang, "StrengthDesc")}</span>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-12 col-lg-6 order-1 order-lg-2 mb-5'>
                    <img src={require('../assets/img/ourStrengthImg.png').default} className="img-fluid stickyParticles" />
                  </div>
                </div>
              </div>
            </div>
            <img src={require('../assets/img/particle21.png').default} className="img-fluid particlePos21 floating" />
          </div>

          <div className='creatorSection position-relative py-5' id="rewards">
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-md-10 col-lg-10'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-12 col-md-12 col-lg-6'>
                    <img src={require('../assets/img/creatorImg.png').default} className="img-fluid stickyParticles" />
                  </div>
                  <div className='col-12 col-md-12 col-lg-6'>
                    <div className='text-center text-lg-left'>
                      <span className="font-CN-Regular sectionHeaderText stickyParticles">{translate(props.lang, "Our Creator")}</span>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionTitleText stickyParticles">{translate(props.lang, "CreatorTitle")}</span>
                      </div>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionDescText stickyParticles">{translate(props.lang, "CreatorDesc")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={require('../assets/img/particle22.png').default} className="img-fluid particlePos22 floating" />
          </div>

          <div className='OurBelief position-relative py-5'>
            <div className='row justify-content-center align-items-center mx-0'>
              <div className='col-12 col-md-10 col-lg-11'>
                <div className='row justify-content-center align-items-center position-relative'>
                  <div className='col-12 col-lg-8 p-0'>
                    <div className='text-center'>
                      <span className="font-CN-Regular sectionHeaderText stickyParticles">{translate(props.lang, "Our Belief")}</span>

                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionTitleText stickyParticles">{translate(props.lang, "BeliefTitle")}</span>
                      </div>
                    </div>
                  </div>
                  <img src={require('../assets/img/particle23.png').default} className="img-fluid particlePos23 floating2" />
                </div>
              </div>
            </div>

            <div className='row justify-content-center align-items-center mx-0'>
              <div className='col-12 col-md-12 col-lg-11'>
                <Slider {...settings2} className="py-5 text-center" style={{ zIndex: "10", position: "sticky" }}>

                  <div className={imageIndex2 === 0 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide1Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 1 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide2Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 2 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide3Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 3 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide4Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 4 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide5Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 5 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide6Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 6 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide7Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 7 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide8Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 8 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide9Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>

                  <div className={imageIndex2 === 9 ? "activeMobileSlide" : "inactiveMobileSlide"} >
                    <img src={require('../assets/img/slide10Phone.png').default} className="img-fluid mx-auto phoneSlideSize" />
                  </div>
                </Slider>


                <div className='row justify-content-center align-items-center'>
                  <div className='col-12 col-md-8'>
                    <div className='text-center'>
                      <div className='pt-4'>
                        <span className="font-CN-Regular sectionDescText">{translate(props.lang, "BeliefDesc")}</span>
                      </div>

                      <div className='mb-5 pt-4'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <div className=''>
                            <img src={require('../assets/img/appleStore.png').default} className="img-fluid downloadAppImg" />
                          </div>
                          <div className='ml-5'>
                            <img src={require('../assets/img/googlePlay.png').default} className="img-fluid downloadAppImg" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </div >
      </div >
      <DarkFooter />
    </>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(Index);
