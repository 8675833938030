/*eslint-disable*/
import React from "react";
import { translate } from 'utils/translate';
import { connect, useDispatch } from 'react-redux';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTelegramPlane } from 'react-icons/fa';
import { Input } from "reactstrap";

function DarkFooter(props) {
  return (
    <footer className="footer py-4" id="subscribe">
      <div className="row justify-content-center align-items-center py-5 mx-0">
        <div className="col-12 col-md-11 col-lg-11">
          <div className="row justify-content-center align-items-center py-5">
            <div className="col-8 col-md-5 col-lg-2 text-center mb-5">
              <img src={require('../../assets/img/footerLogo.png').default} className="img-fluid footerLogoSize" />
            </div>
            <div className="col-10 col-md-6 col-lg-4 mb-5">
              <div className="text-center text-md-left">
                <span className="font-CN-Regular sectionTitleText">
                  {translate(props.lang, "newsletterTitle")}
                </span>

                <div className='pt-4'>
                  <div className='d-flex justify-content-center justify-content-md-start align-items-center'>
                    <div className=''>
                      <FaLinkedinIn fontSize={18} color={'#459DFF'} />
                    </div>
                    <div className='ml-4'>
                      <FaTelegramPlane fontSize={18} color={'#459DFF'} />
                    </div>
                    <div className='ml-4'>
                      <FaFacebookF fontSize={18} color={'#459DFF'} />
                    </div>
                    <div className='ml-4'>
                      <FaInstagram fontSize={18} color={'#459DFF'} />
                    </div>
                  </div>
                </div>

                <div className='pt-5'>
                  <span className="copywriteText">XCUBBE © 2023 All Rights Reserved</span>
                </div>
              </div>
            </div>

            <div className="col-10 col-md-10 col-lg-6 mb-5">
              <div className="">
                <span className="font-CN-Regular sectionDescText">{translate(props.lang, "NewsletterDesc")}</span>
                <div className="d-flex justify-content-start align-items-center pt-4">
                  <Input
                    placeholder={translate(props.lang, "Enter Your Email")}
                    type="text"
                    // name="email"
                    // value={values.firstName}
                    // onChange={handleChange("firstName")}
                    className="footer-textBox py-3"
                  />
                  <div className="subscribeBtn py-3 text-center">
                    <span className="font-CN-Regular text-white">{translate(props.lang, "Subscribe Now")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const mapStateToProps = state => {
  const { i18n } = state;
  return {
    lang: i18n.lang
  }
}
export default connect(mapStateToProps)(DarkFooter);
